import React from "react"
import Page from '../components/Page'
import Layout from "../components/layout"
import { useStoryblokState } from "gatsby-source-storyblok"
import Demo from "../components/Demo"

export default function DemoPage({ pageContext, location }) {
    const story = useStoryblokState(pageContext.story,location)
    
    return (
      <Layout className="layoutclass" meta={{ ...story?.content?.Meta, tracking_variable: story?.content?.tracking_variable }} location={location} full_slug={story?.full_slug} lang={story?.lang} pathname={pageContext.pathname}>
            <Demo story={story} />
      </Layout> 
    )
}
